.section-enableAdmin .error{
	color: #dc3545;
	text-align: center;
}
.section-enableAdmin .response{
	color: green;
	text-align: center;
}
.section-enableAdmin .registros .table tr th {
	border-top: none;
}
.section-enableAdmin .registros .header{
	margin-top: 40px;
}
.section-enableAdmin .title {
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}
.section-enableAdmin .registros .table tbody tr:hover {
	background: rgba(199, 224, 159, 1);
}

.section-enableAdmin .registros .input-group-text {
	background: transparent;
	border-right: none;
}
.section-enableAdmin .registros input {
	border-left: none;
}

.section-enableAdmin .enableEmail .title {
	text-align: center;
}

.section-enableAdmin .enableEmail .lnk-back{
	text-align: right;
}

.section-enableAdmin .enableEmail .content {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.section-enableAdmin .enableEmail .table td {
	border-bottom: 1px solid #dee2e6;
}

.section-enableAdmin .enableEmail .datos {
	margin-top: 20px;
	margin-bottom: 40px;
}

.section-enableAdmin .enableEmail .datos .campo {
	padding-right: 0px;
}
.section-enableAdmin .enableEmail .datos .valor {
	padding-left: 0px;
}


.section-enableAdmin .enableEmail .btn-back {
	background-color: #0ca79b;
	width: 200px;
	padding: 0.5rem 0rem 0.5rem;
	border-radius: 1.75rem;
	align-self: flex-end;
	color: white;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border: none;

	&:hover {
		background-color: #15b7aa;
	}
}

.section-enableAdmin .enableEmail .second-title{
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.section-enableAdmin .enableEmail .roleAdmins{
	padding: 30px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 4px;
	min-height: 240px;
}
.section-enableAdmin .enableEmail .roleAdmins .btn-edit-role{
	float: right;
	background-color: #19887f;
	height: 26px;
	border: none;
	padding-top: 2px;
}

@media (max-width: 768px) {
	.section-enableAdmin .enableEmail .address p span {
		font-size: 13px;
	}
	.section-enableAdmin .enableEmail p {
		font-size: 14px;
	}
}

.section-enableAdmin .admin-main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.section-enableAdmin .encabezado {
	width: 60%;
	display: flex;
	justify-content: center;
	margin-top: 5%;
	margin: auto;
	flex-direction: column;
	background-color: white;
	padding: 3%;
}
.section-enableAdmin .roles {
	width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	flex-direction: column;
	border: 1px solid;
	padding: 2%;
	margin-top: 2%;
}



.section-enableAdmin .registros .btn-enable{
	float: right;
	background-color: #19887f;
	height: 26px;
	border: none;
	padding-top: 2px;
	margin-bottom: 10px;
	width: 160px;
}

.section-enableAdmin .enableEmail .inputGroup{
	width: 400px;
	margin: 0 auto;    
	margin-top: 20px;
}
.section-enableAdmin .enableEmail .inputGroup .btn-enable{
	cursor: pointer;	
}

