@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600&display=swap');

body{	
	font-family: 'Montserrat', sans-serif;
}

$view-port-height: 100vh;
$view-port-width: 100vw;

// smallest screen size (xs – extra small)
$screen-xs-max: 576px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 767px;
// Small tablets (portrait view)
$screen-md-max: 992px;
// Tablets and small desktops
$screen-lg-max: 1200px;
// Large tablets and desktops
$screen-xl-min: 1200px;

// Xtra samll devices
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}

// Small devices
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (max-width: #{$screen-lg-max}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

.basic-row {
	margin: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.basic-col {
	margin: 0px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.page-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.page-title {
	width: 100%;
	font-size: 2rem;
	font-weight: 300;
	color: #0ca79b;
	text-decoration: underline;
	text-underline-position: under;
	margin-bottom: 3rem;
}

.main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	-webkit-box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);

	.card-footer {
		background-color: #e0ecd4;
	}
}

.page-default-content {
	width: 100%;
	text-align: center;
	font-size: 2rem;
	font-weight: 700;
}

.content-table-list .react-bootstrap-table td {
	cursor: pointer;
	margin-top: 60px;
}
