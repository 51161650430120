.title{
	color: #282828;
	font-size: 20px;
	padding: 6px;	
	border-radius: 10px;
	margin-top: 30px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}