.profile-data-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.user-data-row {
		margin: 0px;
		align-items: center;
		width: 100%;
		max-width: 100%;
		justify-content: flex-start;
		color: #086326;
		padding: 1rem;
		font-size: 1.3rem;

		@include md {
			font-size: 1rem;
		}

		@include xs {
			font-size: 0.8rem;
		}

		.icon {
			margin-right: 1rem;
		}
	}
}