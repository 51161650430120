.section-logs .registros .table tr th {
	border-top: none;
}
.section-logs .title {
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}
.section-logs .registros .table tbody tr:hover {
	background: rgba(199, 224, 159, 1);
}




.section-logs .admin-main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.section-logs .filters .btn-buscar{
	background-color: #209e94;
	color: white;
	cursor: pointer;
	width: 146px;
	border: none;
	margin-top: 24px;
	height: 37px;
}
.section-logs .filters .error{
	color: red;
	font-size: 14px;	
}
.section-logs .registros .INFO{
	color: blue;
}
.section-logs .registros .SUCCESS{
	color: green;
}
.section-logs .registros .ERROR{
	color: red;
}
.section-logs .userFound{
	font-weight: bold;
}
.section-logs .userFound span{	
	color: blue;
}
