.login {
  width: 100%;  
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  .gbm-logo {
    width: 15%;
    min-width: 300px;    
  }  

  .login-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 25%;
    min-width: 300px;

    .login-option {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.3rem;

      .login-option-button {
        width: 100%;
        padding: 0.5rem 1.5rem;
        background-color: white;
        color: #086326;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);

        &:hover {
          opacity: 0.8;
        }

        .login-icon {
          width: 10%;
        }

        .button-label {
          width: 80%;
          text-align: center;
        }
      }
    }
  }

  .login-title {
    color: #0ca79b;
    text-align: center;
    font-weight: 700;
    font-size: calc(16px + 0.5vw);
    margin-bottom: 1.3rem;
  }

  .login-footer {
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: calc(300px + 0.75vw);

    .login-form {
      width: 80%;
    }

    .welcome-message {
      color: #0ca79b;
      text-align: center;
      font-weight: 700;
      font-size: calc(16px + 0.5vw);      
    }
  }
}

.login .input-group{
  display: block;
}

.login-input-container {
  margin: 17px 0px;
  width: 100%;
}

.login-input {
  width: 100%;
  background-color: transparent;
  height: calc(1.5em + 0.75rem + 5px);
  border-radius: calc((1.5em + 0.75rem + 5px) / 2);
  border-color: rgba(147, 195, 80, 1);
  color: rgba(147, 195, 80, 1);
  border-width: 1.5px;  

  &.is-invalid {
    border-color: #dc3545;
  }

  &::placeholder {
    font-family: "Montserrat", "FontAwesome";
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: rgba(147, 195, 80, 1);
  }

  &:focus {
    background-color: transparent;
  }
}
.login-input.google2fa{
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.login-formfeedback {
  display: block;
  text-align: center;
  font-size: 0.7rem;
}

.login-button {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  width: 100%;
  height: calc(1.5em + 0.75rem + 5px);
  border-radius: calc((1.5em + 0.75rem + 5px) / 2);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.3rem;
  color: #086326;
  font-weight: 700;

  &:hover {
    color: #086326;
    opacity: 0.8;
  }
}

.login-error-msg {
  font-family: "Montserrat";
  text-align: center;
  color: #dc3545;
  font-size: 0.7rem;
  margin-top: 0.25rem;
  font-weight: 400;  
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.login-modal {
  .modal-content {
    background-color: #f7fdf2;
    color: #086326;
    padding: 2rem;

    .login-modal-row {
      width: 100%;
      margin: 0.3rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &.button {
        width: 60%;
      }
    }

    .login-modal-title {
      font-size: 1.5rem;
      margin: 1rem 0rem;
    }

    .login-modal-col {
      display: flex;
      justify-content: center;

      &.store {
        margin-bottom: 1.5rem;
      }
    }

    .modal-img {
      height: 100px;
      width: auto;
    }

    .modal-google-row {
      width: 100%;
      margin: 0.7rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .modal-descarga-app {
      margin: 0rem 1rem;
      max-width: 100%;

      &.android {
        -webkit-box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
        -moz-box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
        box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
      }

      &.ios {
        -webkit-box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
        -moz-box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
        box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
      }
    }

    .modal-qr {
      height: 150px;
      width: auto;
    }

    .login-modal-email-img {
      width: 100%;
      height: auto;
    }

    .login-modal-error-title {
      font-size: 1.2rem;
      margin: 1rem 0rem;
    }
  }
}

.forgotPass .gbm-logo{
  margin-bottom: 30px;
}
.forgotPass .login-error-msg{  
  text-align: center;
  flex: auto;
}
.forgotPass .login-form .login-input{
  
}
.modal-forgotPass .modal-content{
  text-align: center;
}
.modal-password-reset .modal-content{
  text-align: center;
}
.modal-password-reset .modal-content .login-button{
  width: 60%;
}

.login-modal .input-group-text{
  background-color: transparent;
  border: 1px solid #93c350;
  cursor: pointer;
  color: #93c350;
}

.login .back{
  width: 90%;
}

.passwordReset .gbm-logo{
  margin-bottom: 20px;
}
.passwordReset .login-input{  
}
.passwordReset .login-error-msg{
  font-family: "Montserrat";
  text-align: center;
  color: #dc3545;
  font-size: 0.7rem;  
  font-weight: 400;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word; 
}

@media(max-width: 768px){

  .login {
    height: auto;
  }
  .modal-password-reset .modal-content .login-button{
    width: 90%;
  }

}