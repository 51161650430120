.news-button {
  height: 8%;
  min-height: 2rem;
  min-width: 8rem;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  border: none;
  position: relative;

  .button-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    border: none;
    box-shadow: unset;
  }
}
