.section-profiles-all-movements .registros .table tr th {
	border-top: none;
}
.section-profiles-all-movements .title {
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}
.section-profiles-all-movements .registros .table tbody tr:hover {
	background: rgba(199, 224, 159, 1);
}

.section-profiles-all-movements .input-group-text {
	background: transparent;
	border-right: none;
}
.section-profiles-all-movements input {
	border-left: none;
}


.section-profiles-all-movements .detail .title {
	text-align: center;
}

.section-profiles-all-movements .detail .lnk-back{
	text-align: right;
}

.section-profiles-all-movements .detail .content {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.section-profiles-all-movements .detail .table td {
	border-bottom: 1px solid #dee2e6;
}

.section-profiles-all-movements .detail .datos {
	margin-top: 20px;
	margin-bottom: 40px;
}

.section-profiles-all-movements .detail .datos .campo {
	padding-right: 0px;
}
.section-profiles-all-movements .detail .datos .valor {
	padding-left: 0px;
}
.section-profiles-all-movements .detail .footer {
	text-align: center;
}

.section-profiles-all-movements .detail .btn-back {
	background-color: #0ca79b;
	width: 200px;
	padding: 0.5rem 0rem 0.5rem;
	border-radius: 1.75rem;
	align-self: flex-end;
	color: white;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border: none;

	&:hover {
		background-color: #15b7aa;
	}
}

.section-profiles-all-movements .detail .second-title{
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.section-profiles-all-movements .main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.section-profiles-all-movements .encabezado {
	width: 60%;
	display: flex;
	justify-content: center;
	margin-top: 5%;
	margin: auto;
	flex-direction: column;
	background-color: white;
	padding: 3%;
}

.section-profiles-all-movements .sectionVaults img{
	width: 70px;
	margin-bottom: 10px;
}
.section-profiles-all-movements .sectionVaults .cargando{
	font-size: 16px;
	margin-bottom: 16px;
	font-style: italic;
	font-weight: bold;
	text-align: center;
}

@media (max-width: 768px) {
	.section-profiles-all-movements .detail .address p span {
		font-size: 13px;
	}
	.section-profiles-all-movements .detail p {
		font-size: 14px;
	}
}

