.error{
	color: red;
}
.btnSearch{
	background-color: #209e94;
	color: white;
	cursor: pointer;
	width: 146px;
	border: none;
	margin-top: 24px;
	height: 37px;
}
.filterBetweenDates{
	margin-bottom: 30px;
}
.searching{
	color: blue;
	margin-top: 30px;
	margin-bottom: 0px;
	font-size: 18px;
}