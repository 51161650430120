.signup {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: absolute;

	.gbm-logo {
		width: 15%;
		min-width: 300px;
		margin-top: 20px;
	}

	.welcome-message {
		color: #0ca79b;
		text-align: center;
		font-weight: 700;
		font-size: calc(16px + 0.5vw);
		margin-bottom: 20px;
		font-size: 22px;
	}

	.signup-title {
		color: #0ca79b;
		text-align: center;
		font-weight: 700;
		font-size: calc(16px + 0.5vw);
		margin-bottom: 1.3rem;
	}

	.signup-options {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		width: 25%;
		min-width: 300px;

		.signup-option {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1.3rem;

			.signup-option-button {
				width: 100%;
				padding: 0.5rem 1.5rem;
				background-color: white;
				color: #086326;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				cursor: pointer;
				-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
				-moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
				box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);

				&:hover {
					opacity: 0.8;
				}

				.signup-icon {
					width: 10%;
				}

				.button-label {
					width: 80%;
					text-align: center;
				}
			}
		}
	}

	.form-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 20px;

		.signup-form {
			width: 40%;
		}
	}

	.signup-input-container {
		margin-bottom: 14px;
		width: 100%;
	}

	.signup-input {
		width: 100%;
		background-color: transparent;
		height: calc(1.5em + 0.75rem + 5px);
		border-radius: calc((1.5em + 0.75rem + 5px) / 2);
		border-color: rgba(147, 195, 80, 1);
		color: rgba(147, 195, 80, 1);
		border-width: 1.5px;

		&.is-invalid {
			border-color: #dc3545;
		}

		&::placeholder {
			font-family: 'Montserrat', 'FontAwesome';
			display: flex;
			align-items: center;
			font-size: 0.85rem;
			color: rgba(147, 195, 80, 1);
		}
	}

	.signup-formfeedback {
		display: block;
		text-align: center;
		font-size: 0.7rem;
	}

	.signup-button {
		margin-top: 0.75rem;
		margin-bottom: 1rem;
		width: 250px;
		height: calc(1.5em + 0.75rem + 5px);
		border-radius: calc((1.5em + 0.75rem + 5px) / 2);
		border-width: 2.5px;
		border-color: #086326;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		font-size: 1.3rem;
		color: #086326;
		font-weight: 700;

		&:hover {
			color: #086326;
			opacity: 0.8;
		}
	}

	.signup-error-msg {
		font-family: 'Montserrat';
		text-align: center;
		color: red;
		font-size: 0.8rem;
		font-weight: 500;
		white-space: pre-wrap; /* css-3 */
		white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
		white-space: -pre-wrap; /* Opera 4-6 */
		white-space: -o-pre-wrap; /* Opera 7 */
		word-wrap: break-word; /* Internet Explorer 5.5+ */
	}

	.signup-footer {
		width: 100%;
		margin: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0px;
		margin-bottom: 30px;
	}

	.custom-select-input-placeholder{
		cursor: pointer;
	}

}

.signup-modal {
	.modal-content {
		background-color: #f7fdf2;
		color: #086326;
		padding: 2rem;

		.signup-modal-row {
			width: 80%;
			margin: 0.3rem 0rem;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			&.button {
				width: 60%;
			}
		}

		.signup-modal-title {
			font-size: 1.5rem;
			margin: 1rem 0rem;
		}

		.signup-modal-col {
			display: flex;
			justify-content: center;

			&.store {
				margin-bottom: 1.5rem;
			}
		}

		.modal-img {
			height: 100px;
			width: auto;
		}

		.modal-google-row {
			width: 100%;
			margin: 0.7rem 0rem;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		.modal-descarga-app {
			margin: 0rem 1rem;
			max-width: 100%;

			&.android {
				-webkit-box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
				-moz-box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
				box-shadow: -5px 10px 2px 0px rgba(168, 207, 169, 1);
			}

			&.ios {
				-webkit-box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
				-moz-box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
				box-shadow: 5px 10px 2px 0px rgba(168, 207, 169, 1);
			}
		}

		.modal-qr {
			height: 150px;
			width: auto;
		}

		.signup-modal-email-img {
			width: 100%;
			height: auto;
		}

		.signup-modal-error-title {
			font-size: 1.2rem;
			margin: 1rem 0rem;
		}
	}
}

.modal-content .signup {
	position: relative;
}

.go-back-link {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: #0056b3;
		text-decoration: underline;
	}
}

.signup .back {
	width: 40%;
}

.signup .form-container .signup-sms {
	width: 100%;
}

.signup .inputSMS {
	text-align: center;
}
.signup .signup-form .documents {
	font-size: 12px;
	width: 102%;
}
.signup .signup-form .documents label {
	display: inline;
}
.signup .signup-form .documents .form-check-input {
	display: inherit;
	width: auto;
	height: auto;
}
.signup .signup-form .documents .signup-input-container {
	margin-bottom: 0px;
}

.signupEmail {
	display: block;
	height: 101%;
}
.signupEmail .spinner {
	margin: 0 auto;
	display: block;
	margin-top: 60px;
}

@media (max-width: 768px) {
	.signup {
		height: auto;
	}
	.signup .form-container .signup-form {
		width: 90%;
	}
	.signup .signup-button {
		width: 70%;
	}
	.signup .back {
		width: 90%;
	}
	.signup .signup-form .documents {
		width: 94%;
	}
}

.signup-input-container .dropdown-item {
	display: flex !important;
}
