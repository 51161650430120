.section{
	margin: 0 auto;
}
.title{
	font-size: 16px;	
}
.back{
	text-align: right;
}
.address{
	text-align: center;
}