.banner{
	text-align: center;
	font-weight: bold;
}
.options{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.options p{
	padding: 10px;
	padding-left: 30px;
	padding-right: 30px;	
	margin-right: 6px;	
	cursor: pointer;
	border: none;
}
.active{
	color: white;
	background-color: #19887f;
}
.inactive{
	color: #888787;
	background-color: #ebecec;
}
.one{	
	margin-right: 6px;	
}
.two{	
	margin-left: 6px;
}
.link:hover{
	text-decoration: none;
}