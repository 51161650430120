.button{	
	background-color: #209e94;
	height: 40px;
	border: none;
	padding-top: 4px;
	
	&:first-letter{
		text-transform: capitalize;
	}

}