#wrapper {
	display: flex;
	flex-flow: column;
	padding: 0px;
	margin: 0px;
	max-width: 100%;
	width: 100%;
	height: 100%;
}

.navbar-gbm {
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #cccccc;
	color: #0ca79b;
	align-items: center;
	padding-left: 40px;

	.nav-icons {
		margin-left: 30px;
		display: flex;
		align-items: center;
	}

	.dropdown-menu {
		padding: 0px;
	}

	.notification {
		cursor: pointer;
		padding: 10px;

		.notification-title {
			width: 100%;
			margin: 0px;
			align-items: center;
			justify-content: flex-start;
			color: #0ca79b;
			flex-wrap: wrap;
		}

		.notification-date {
			width: 100%;
			margin: 0px;
			align-items: center;
			justify-content: flex-start;
			color: gray;
		}

		&:hover {
			background-color: #eaf2d3;

			.notification-title {
				color: #046b63;
			}
		}
	}

	.profile-dropdown {
		margin-left: 30px;
		margin-right: 30px;
	}

	.nav-profile-img-container {
		display: flex;
		position: relative;
	}

	.nav-profile-img {
		z-index: 999;
		left: 0;
		top: 0;
	}

	.img-avatar {
		position: absolute;
		left: 0;
		top: 4px;
		clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
	}
}

.mainlayout-content {
	display: flex;
	width: 100%;
	flex-grow: 1;
	position: relative;
}

.sidebar-profile-photo {
	padding: 25px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #dadada;
}

.mobile-sidebar {
	height: 100%;
	margin: 0px;
	width: 60%;
	top: 0;
	bottom: 0;
	left: 0;
	border: none;
	border-radius: 0rem;
	position: absolute;

	.modal-content {
		height: 100%;
		border-radius: unset;
	}

	.mobile-sidebar-item {
		width: 100%;
		padding: 1.5rem 1rem;
		margin: 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #dadada;
		cursor: pointer;

		&:hover {
			background-color: #e0ecd4;
		}

		.mobile-sidebar-item-icon {
			padding: 0px;
			justify-content: center;
			align-items: center;
		}

		.mobile-sidebar-item-label {
			padding: 0px;
			font-size: 0.95rem;
			font-weight: 300;
		}
	}
}

.collapsed-sidebar {
	display: none;
	background-color: white;
	z-index: 10;
	-webkit-box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	-webkit-transition: margin 0.5s ease-out;
	-moz-transition: margin 0.5s ease-out;
	-o-transition: margin 0.5s ease-out;
	transition: margin 0.5s ease-out;
	color: #0ca79b;

	@include sm {
		display: none;
	}

	&.collapsed {
		display: block;

		@include sm {
			display: none;
		}
	}

	.collapsed-sidebar-item {
		padding: 1.5rem 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #dadada;
		cursor: pointer;

		.label {
			flex-basis: 100%;
			text-align: center;
			padding-top: 0.25rem;
			height: 1rem;
			font-size: 1rem;
			width: 4rem;
			margin: 0rem;
			display: block;
			color: black;
			font-weight: 700;
			-webkit-transform: translateX(-250px);
			transform: translateX(-250px);
			-webkit-transition: all 0.15s ease;
			transition: all 0.15s ease;
		}

		&:hover {
			background-color: #e0ecd4;

			.label {
				-webkit-transform: translateX(0);
				transform: translateX(0);
			}
		}
	}
}

.expanded-sidebar {
	flex: 1;
	flex-direction: column;
	height: 100%;
	background-color: white;
	z-index: 10;
	-webkit-box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	box-shadow: 9px 0px 15px -9px rgba(0, 0, 0, 0.75);
	-webkit-transition: margin 0.5s ease-out;
	-moz-transition: margin 0.5s ease-out;
	-o-transition: margin 0.5s ease-out;
	transition: margin 0.5s ease-out;

	@include sm {
		display: none;
	}

	&.collapsed {
		display: none;
	}

	.expanded-sidebar-profile-section {
		padding: 10px 15px 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #dadada;
		color: #0ca79b;

		.expanded-sidebar-collapse-button {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			.collapse-icon {
				color: #0ca79b;
				cursor: pointer;
			}
		}

		.expand-sidebar-profile-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0px;
			width: 100%;
			padding: 10px;
			flex-wrap: nowrap;
			font-size: 0.85rem;
			justify-content: flex-start;

			.expand-sidebar-profile-icon {
				padding: 0px;
			}

			.expand-sidebar-profile-label {
				padding: 0px;

				&.name {
					font-size: 1.3rem;
					font-weight: 700;
				}
			}
		}
	}

	.expand-sidebar-item {
		margin: 0px;
		display: flex;
		flex-direction: row;
		width: 250px;
		padding: 20px 15px;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #dadada;
		cursor: pointer;

		&:hover {
			background-color: #e0ecd4;
		}

		.expand-sidebar-item-icon {
			padding: 0px;
			justify-content: center;
			align-items: center;
		}

		.expand-sidebar-item-label {
			padding: 0px;
			font-size: 0.95rem;
			font-weight: 300;
		}
	}
}

.expand-sidebar-item-2 {
	margin: 0px;
	display: flex;
	flex-direction: row;
	width: 250px;
	padding: 20px 15px;
	justify-content: left;
	align-items: center;
	border-bottom: 1px solid #dadada;
	cursor: pointer;
	&:hover {
		background-color: #e0ecd4;
	}
	.expand-sidebar-item-icon {
		padding: 0px;
		justify-content: center;
		align-items: center;
	}

	.expand-sidebar-item-label {
		padding: 0px;
		font-size: 0.95rem;
		font-weight: 300;
	}
}

.page-content {
	background-color: #f2f3f5;
	display: flex;
	width: 100%;
	padding: 1rem 1.8rem;
}


.main-layout-modal {
  .modal-content {
    background-color: #f7fdf2;
    color: #086326;
    padding: 2rem;
    justify-content: center;
    align-items: center;

    .main-layout-modal-row {
      @extend .basic-row;
      width: 80%;
      margin: 0.3rem 0rem;
      text-align: center;
    }

    .modal-img {
      height: 100px;
      width: auto;
      margin-top: 8em;
    }

    .main-layout-modal-title {
      font-size: 1.5rem;
      margin: 1rem 0rem;
    }

    .main-layout-modal-description {
      font-size: 1rem;
      width: 90%;
    }

    .main-layout-modal-button {
      margin-top: 0.75rem;
      margin-bottom: 1rem;
      width: 100%;
      height: calc(1.5em + 0.75rem + 5px);
      border-radius: calc((1.5em + 0.75rem + 5px) / 2);
      border-width: 2.5px;
      border-color: #086326;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-size: 1.3rem;
      color: #086326;
      font-weight: 700;

      &:hover {
        color: #086326;
        opacity: 0.8;
      }
    }
  }
}