.title{
	text-align: center;
	color: black;
	font-size: 22px;
	margin-bottom: 20px;

	&:first-letter{
		text-transform: capitalize;
	}
}

.frame{
	border: 1px solid #dfdada;
	padding: 30px;
}

.boxTitle{
	font-weight: bold;
    margin-top: 20px;
    display: flex;
}

.devButton{
	text-align: center;
}
.devButton button{
	width: 160px;
}