.news-title {
  color: #0ca79b;
  font-size: calc(16px + 0.5vw);
  font-weight: 700;
  margin-bottom: 1rem;
  width: 60%;
  text-align: center;
}

.carousel-img {
  width: 100% !important;
}

.news-carousel {
  margin-bottom: 1rem;
}

.news-list {
  margin: 1.5rem 0rem;

  .news-item {
    width: 100%;
    margin: 15px 0px;
    padding: 10px 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dadada;

    .news-item-title {
      font-size: calc(12px + 0.5vw);
      margin: 0px;
      align-items: flex-start;
      color: #99bf58;
      font-weight: 700;
    }

    .news-item-link {
      font-size: calc(12px + 0.5vw);
      margin: 0px;
      justify-content: flex-end;
      align-items: flex-end;
      color: #0ca79b;
      text-decoration: none;

      a {
        color: #0ca79b;
        text-decoration: none;
        font-weight: 700;
      }
    }

    .news-img {
      width: 100%;
    }
  }
}
