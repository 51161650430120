// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Libraries
@import 'bootstrap/scss/bootstrap.scss';

// General Config and Classes
@import 'general/layout.scss';
@import 'general/public-layout.scss';
@import 'general/mainlayout.scss';

//Pages
@import 'pages/signup.scss';
@import 'pages/login.scss';
@import 'pages/news.scss';
@import 'pages/administradores.scss';
@import 'pages/movimientos.scss';
@import 'pages/profile.scss';
@import 'pages/roles.scss';
@import 'pages/users.scss';
@import 'pages/codigosdedescuento.scss';
@import 'pages/salesByCode.scss';
@import 'pages/enableAdmin.scss';
@import 'pages/assignEarnings.scss';
@import 'pages/internalEarnings.scss';
@import 'pages/usersMovements.scss';
@import 'pages/logs.scss';
@import 'pages/usersAddresses.scss';
@import 'pages/accounting.scss';
  
// profiles
@import 'profiles/movements.scss';
@import 'profiles/ventas.scss';

// Components
@import 'components/public-navbar.scss';
@import 'components/public-footer.scss';
@import 'components/news-button.scss';
