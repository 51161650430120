.navbar-app {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(147, 195, 80, 1) 39%,
    rgba(12, 167, 155, 1) 94%
  );
}

.navbar-brand {
  margin-right: 0px !important;
  max-width: 80%;
}

.gbm-navbar-logo {
  cursor: pointer;
  border-style: none;
  vertical-align: top;
  max-height: 80px;
  max-width: 80%;
  width: auto;
  height: auto;
}

.navbar-toggler {
  border-color: white;
  color: white;
  max-width: 20%;
  margin-right: 0px;

  &:hover {
    background-color: transparent;
    border-color: gold;
    color: gold;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 199, 43, 0.5);
    border-color: gold;
  }
}

.navbar-item {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-link {
  color: white;
  font-weight: 500;

  &:hover {
    color: gold;
  }
}

.navbar-button {
  background-color: white;
  font-size: 1.2rem;
  color: #14a50d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;

  &:hover {
    background-color: #14a50d;
    color: white;
  }
}
