.section-users .registros .table tr th {
	border-top: none;
}
.section-users .title {
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}
.section-users .table tbody tr:hover {
	background: rgba(199, 224, 159, 1);
}

.section-users .input-group-text {
	background: transparent;
	border-right: none;
}
.section-users input {
	border-left: none;
}

.section-users .history {
	margin-top: 60px;
}
.section-users .detail .title {
	text-align: center;
}

.section-users .detail .lnk-back{
	text-align: right;
}

.section-users .detail .content {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.section-users .detail .table td {
	border-bottom: 1px solid #dee2e6;
}

.section-users .detail .datos {
	margin-top: 20px;
}
.section-users .detail .datos p {
	padding-bottom: -5px;
	border-bottom: 1px solid #dee2e6;
}

.section-users .detail .datos .campo {
	padding-right: 0px;
}
.section-users .detail .datos .valor {
	padding-left: 0px;
}
.section-users .detail .footer {
	text-align: center;
}

.section-users .detail .btn-back {
	background-color: #0ca79b;
	width: 200px;
	padding: 0.5rem 0rem 0.5rem;
	border-radius: 1.75rem;
	align-self: flex-end;
	color: white;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border: none;

	&:hover {
		background-color: #15b7aa;
	}
}

.section-users .detail .second-title{
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.section-users .detail .movimientos{
	padding: 30px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 4px;
	min-height: 240px;
}
.section-users .detail .movimientos .btn-edit-role{
	float: right;
	background-color: #19887f;
	height: 26px;
	border: none;
	padding-top: 2px;
}

@media (max-width: 768px) {
	.section-users .detail .address p span {
		font-size: 13px;
	}
	.section-users .detail p {
		font-size: 14px;
	}
}

.section-users .admin-main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.section-users .encabezado {
	width: 60%;
	display: flex;
	justify-content: center;
	margin-top: 5%;
	margin: auto;
	flex-direction: column;
	background-color: white;
	padding: 3%;
}
.section-users .roles {
	width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	flex-direction: column;
	border: 1px solid;
	padding: 2%;
	margin-top: 2%;
}

.section-users .centrarPresentacion {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3%;
	flex-direction: column;
}

.section-users .details-content-admin {
	background-color: #f2f3f5;
	height: 95vh;
	width: 100%;
	padding: 1rem 1.8rem;
}

.section-users .admin-details-props a {
	margin-left: 6%;
	background-color: white;
}

.section-users .admin-button {
	margin-top: 5%;
	display: flex;
	justify-content: right;
	margin-bottom: 5%;
	align-items: center;
}
.section-users .admin-check-group {
	display: flex;
	justify-content: space-evenly;
	margin: 1%;
}

.section-users .users-list .react-bootstrap-table td {
	cursor: pointer;
}

.section-users .admin-button-edit-role {
	color: #282828;

	font-weight: 600;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.section-users .admin-button-volver-role {
	color: #282828;
	padding-left: 17px !important;
	padding-right: 17px !important;
	font-weight: 600;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.section-users .admin-table-role-list {
	margin-top: 3%;
}
.section-users .admin-table-role-list .card-title {
	text-align: center;
	margin-bottom: 3%;
}

.section-users .admin-delete {
	cursor: pointer;
	font-weight: 600;
	border-radius: 22px;
	border: 1px solid;
	padding: 9px;
}

.section-users .admin-delete-role {
	cursor: pointer;
}

.section-users .admin-details-props span {
	font-weight: 600;
}
.section-users .role-cancelar-button {
	cursor: pointer;
}

.section-users .roles-list .react-bootstrap-table td {
	cursor: pointer;
}

.section-users .table-role-list {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.section-users .table-list-role .card-title {
	text-align: center;
}
.section-users .table-list-role .card-text {
	text-align: center;
}

.section-users .movimientos-button-volver {
	text-align: center;
}

.section-users .admin-style-button {
	background-color: #209e94;
	width: 150px;
	height: 44px;
	border: none;
	font-size: 0.8rem;
}

.section-users .users-list .tr-user{
	cursor: pointer;
}

.section-users .movimientos .tr-move{
	cursor: pointer;
}

.modalActionUser .btn-confirm {
	background-color: #19887f;	
	height: 44px;
	border: none;
}
.modalActionUser .btn-confirm:hover {
	background-color: #12736b;	
	height: 44px;
	border: none;
}
.modalActionUser .btn-cancel {	
	height: 44px;
	border: none;
}

.modalResponseUser .btn-exit-response {
	background-color: #19887f;	
	height: 44px;
	border: none;
}
.modalResponseUser .btn-exit-response:hover {
	background-color: #12736b;	
	height: 44px;
	border: none;
}
