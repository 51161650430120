.public-layout {
  height: 100%;
  width: 100%;
}

.public-layout-content {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-family: "Montserrat";
}

.section-number {
  width: 100%;
  font-size: calc(16px + 5vw);
  text-align: center;
  font-weight: 900;
}

.section-title {
  width: 100%;
  margin-bottom: 2rem;
  font-size: calc(16px + 3vw);
  text-align: center;
  font-weight: 300;

  strong {
    font-weight: 700;
  }
}

.section-container {
  width: 100%;
  height: 48.1vw;
  padding-bottom: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 48.1vw;
  background-position: center;

  .text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      width: 100%;
      font-size: calc(16px + 2vw);
      text-align: center;
    }

    .subtitle {
      width: 100%;
      font-size: calc(16px + 1vw);
      text-align: center;
    }
  }
}
