
.section-movimientos .detail .title {
	text-align: center;
}

.section-movimientos .detail .content {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.section-movimientos .detail .table td {
	border-bottom: 1px solid #dee2e6;
}

.section-movimientos .detail .datos {
	margin-top: 20px;
}
.section-movimientos .detail .datos p {
	padding-bottom: -5px;
	border-bottom: 1px solid #dee2e6;
}

.section-movimientos .detail .datos .campo {
	padding-right: 0px;
}
.section-movimientos .detail .datos .valor {
	padding-left: 0px;
}
.section-movimientos .detail .footer {
	text-align: center;
}

.section-movimientos .detail .btn-back {
	background-color: #0ca79b;
	width: 200px;
	padding: 0.5rem 0rem 0.5rem;
	border-radius: 1.75rem;
	align-self: flex-end;
	color: white;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border: none;

	&:hover {
		background-color: #15b7aa;
	}
}

@media (max-width: 768px) {
	.section-movimientos .detail .address p span {
		font-size: 13px;
	}
	.section-movimientos .detail p {
		font-size: 14px;
	}
}

.table-list-mov {
	padding: 20%;
}
