.section-usersAddresses .registros .table tr th {
	border-top: none;
}
.section-usersAddresses .title {
	color: #282828;
	font-size: 14px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}
.section-usersAddresses .registros .table tbody tr:hover {
	background: rgba(199, 224, 159, 1);
}

.section-usersAddresses .input-group-text {
	background: transparent;
	border-right: none;
}
.section-usersAddresses input {
	border-left: none;
}

.section-usersAddresses .admin-main-card {
	width: 100%;
	padding: 2rem 1rem;
	align-self: center;
	box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.5);
}
.section-usersAddresses .totals .img-crypto{
	width: 50px;
}

.section-usersAddresses .totals .info-box {
    display: block;
    min-height: 80px;
    background: #fff;
    width: 100%;    
    border: 1px solid #eceaea;
	border-radius: 4px;
	-webkit-box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	-moz-box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	margin-bottom: 18px;
}

.section-usersAddresses .totals .info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;    
}
.section-usersAddresses .totals .info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
}
.section-usersAddresses .totals .info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}


