.section{
	padding-left: 200px;
	padding-right: 200px;	
}
.back{
	text-align: right;
}
.title {
	color: #282828;
	font-size: 16px;
	padding: 6px;
	border-radius: 10px;

	background: rgba(199, 224, 159, 1);
	background: -moz-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(199, 224, 159, 1)),
		color-stop(100%, rgba(156, 211, 205, 1))
	);
	background: -webkit-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -o-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: -ms-linear-gradient(left, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	background: linear-gradient(to right, rgba(199, 224, 159, 1) 0%, rgba(156, 211, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7e09f', endColorstr='#9cd3cd', GradientType=1 );
}

.amount{
	width: 300px;
}

.btnDeclare{
	margin: 0 auto;
	background-color: #19887f;	
	border: none;
	padding-top: 2px;
	margin-bottom: 10px;
	margin-top:30px;
	height: 40px;	
}

.response{
	color: green;
	text-align: center;
}

.error{
	color: red;
	text-align: center;
}

.message{
	color: green;
	text-align: center;
	font-style: italic;
}
.declaredAt{
	display: flex;
}
.declaredAtDate{
	width: 47%;
	border-right: none;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	padding-right: 0px;
	padding-left: 7px;
	-webkit-clip-path: inset(0 14px 0 0);
	clip-path: inset(0 14px 0 0);
}
.declaredAtTime{
	width: 60%;
	border-left: none;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-left: -14px;
	padding-left: 0px;
}

.select{
	width: 300px;
	margin-bottom: 16px;
}

@media(max-width: 768px){

	.section{
		padding-left: 0px;
		padding-right: 0px;	
	}
	.colDeclaredAt{		
		margin-top:14px;
	}

}