.title{
	text-align: center;
	padding: 10px;
	border-top: 1px solid black;
	border-bottom: 1px solid black;	
	text-align: center;
	font-size: 26px;
	margin-bottom: 30px;
}

.vault {
    display: block;
    min-height: 80px;
    background: #fff;
    width: 100%;    
    border: 1px solid #eceaea;
	border-radius: 4px;
	-webkit-box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	-moz-box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	box-shadow: 2px 4px 5px 0px rgba(184,180,184,1);
	margin-bottom: 18px;
}

.logoFrame {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;    
}

.logo{
	width: 60px;
}

.content {
    padding: 5px 10px;
    margin-left: 90px;
}
.number {
    display: inline;
    font-weight: bold;
    font-size: 18px;
}
.action{	
	font-size: 14px;
}
.popoverBalance{
    cursor: pointer;
    color: #b30c0c;
}